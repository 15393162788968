<template>
  <div>
    <div class="tw-container">
      <div class="row">
        <div class="col-sm-6 col-12 mb-sm-0 mb-2">
          <p class="tw-text-size18 tw-border-start fw-bolder">付費紀錄頁面</p>
        </div>
        <div class="col-sm-6 col-12">
          <p>
            <span
              class="text-danger fw-bolder"
              v-if="subscribeResult === '目前尚未訂閱 !'"
              >{{ subscribeResult }}</span
            >
            <span class="text-success fw-bolder" v-else>{{
              subscribeResult
            }}</span>
          </p>
        </div>
      </div>
      <hr />
      <div class="mb-4">
        <router-link
          to="/ETwingo/serve?from=paymentRecord&mode=all"
          class="tw-btn tw-btn-gold me-2"
          v-if="!canNotGoPayPlan"
          >前往購買方案 ^^</router-link
        >
        <router-link
          to="/user/storeLists"
          class="tw-btn tw-btn-gold me-2"
          v-else
          >立即前往領取一個月試用期 ^^</router-link
        >
        <router-link
          v-if="subscribeResult !== '目前尚未訂閱 !'"
          to="/ETwingo/serve?from=paymentRecord&mode=helper"
          class="tw-btn tw-btn-gold"
          >小幫手</router-link
        >
      </div>
      <table class="table table-hover mb-3">
        <thead>
          <tr>
            <th>方案</th>
            <th>購買金額</th>
            <th>購買時間</th>
            <th>是否回報付款</th>
            <th>是否啟用</th>
            <th>回報付款</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in paymentRecords" :key="data.id">
            <td>
              <img
                title="刪除"
                class="icon me-2 d-inline-block"
                src="@/assets/icon/trash-2.png"
                alt="刪除"
                @click="showModal('setDeleted', data)"
                v-if="
                  !data.paid &&
                  data.paymentVerifyRecords.length === 0 &&
                  data.planType === 'NORMAL'
                "
              />
              <p class="mb-2" v-if="data.paymentRecordProjectLinks.length > 0">
                <span
                  class="d-inline-block"
                  v-if="data.planType === 'FREE_TRIAL'"
                >
                  試用期方案
                </span>
                <span v-else-if="data.planType === 'NORMAL'"
                  >訂閱{{ data.paymentRecordProjectLinks[0].period }}個月</span
                >
                <span v-else>推薦獎勵 1 個月</span>
              </p>
              <p
                class="text-primary pointer"
                v-if="data.paymentRecordPermissionLinks.length > 0"
                @click="
                  showModal(
                    'checkPermission',
                    data.paymentRecordPermissionLinks
                  )
                "
              >
                小幫手{{ data.paymentRecordPermissionLinks.length }}人
              </p>
            </td>
            <td>NT${{ data.money }}元</td>
            <td>
              {{
                $methods.moment(data.createTime).format("YYYY-MM-DD HH:mm:ss")
              }}
            </td>
            <td>
              <p
                class="text-success"
                v-if="data.paymentNotification.createTime"
              >
                已回報
              </p>
              <p class="text-danger" v-else>尚未回報</p>
            </td>
            <td>
              <p class="text-success" v-if="data.paid">已啟用</p>
              <p class="text-danger" v-else>尚未啟用</p>
            </td>
            <td>
              <div
                v-if="
                  !data.paid &&
                  data.paymentVerifyRecords.length === 0 &&
                  data.money > 0
                "
              >
                <button
                  class="tw-btn tw-btn-danger"
                  v-if="!data.paymentNotification.createTime"
                  @click="showModal('paymentNotificationCreate', data)"
                >
                  已付款通知
                </button>
                <button
                  class="tw-btn tw-btn-success"
                  v-else
                  @click="showModal('paymentNotificationUpdate', data)"
                >
                  編輯已付款通知
                </button>
              </div>
              <p
                class="text-danger"
                v-else-if="!data.paid && data.paymentVerifyRecords.length > 0"
              >
                方案未通過!
              </p>
              <p v-else-if="data.paid && data.paymentVerifyRecords.length > 0">
                <span v-if="data.paymentRecordProjectLinks.length > 0">
                  <span
                    class="text-success"
                    v-if="
                      data.paymentRecordProjectLinks[0].subscriptionProject
                        .expirationTime >=
                      new Date() * 1
                    "
                    >方案已啟用!</span
                  >
                  <span class="text-danger" v-else>方案已過期</span>
                </span>
                <span class="text-success" v-else>小幫手方案</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!-- 建立/編輯通知付款的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="paymentNotificationModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            <span v-if="paymentNotificationStatus === 'create'">建立</span>
            <span v-else>編輯</span>付款通知
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul>
            <!-- 付款方式 -->
            <li>
              <h4 class="tw-border-start fw-bolder mb-3">付款方式</h4>
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="ReceiptTitle"
                  placeholder="付款方式"
                  value="匯款"
                  disabled
                />
                <label for="ReceiptTitle">付款方式</label>
              </div>
              <ul class="text-start fw-bolder alert alert-warning">
                <li class="mb-2">[ 匯款帳號 ]</li>
                <li class="mb-2">中國信託銀行 豐原分行</li>
                <li class="mb-2">銀行代號: (822)</li>
                <li class="mb-2">帳號: 392540825738</li>
                <li class="mb-2">戶名: 優碼興科技股份有限公司</li>
              </ul>
            </li>
            <hr />
            <!-- 付款通知 -->
            <li>
              <h4 class="tw-border-start fw-bolder mb-3">付款通知</h4>
              <div>
                <!-- 銀行代碼 -->
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="BankCode"
                    placeholder="搜尋銀行"
                    v-model="searchBankCode"
                  />
                  <label for="BankCode">搜尋銀行</label>
                </div>
                <div
                  class="border p-3 mb-5"
                  style="height: 200px; overflow-y: scroll"
                >
                  <ul class="list" v-if="!banksCode.loading">
                    <li
                      class="text-start"
                      v-for="data in banksCode.data"
                      :key="data.bankCode"
                    >
                      <input
                        :id="`bank${data.bankCode}`"
                        type="radio"
                        name="bank"
                        :value="data.bankCode"
                        v-model="paymentNotification.bankCode"
                      />
                      <label :for="`bank${data.bankCode}`"
                        >{{ data.bankCode }} {{ data.name }}</label
                      >
                    </li>
                  </ul>
                  <AreaLoading v-else></AreaLoading>
                </div>
                <!-- 末五碼 -->
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="LastFiveDigit"
                    placeholder="帳號末五碼"
                    maxlength="5"
                    v-model="paymentNotification.accountNumber"
                  />
                  <label for="LastFiveDigit">帳號末五碼</label>
                </div>
                <!-- 匯款金額 -->
                <div class="form-floating mb-3">
                  <input
                    onwheel="this.blur()"
                    type="number"
                    class="form-control"
                    id="RemittanceAmount"
                    placeholder="匯款金額"
                    :value="paymentNotification.money"
                    disabled
                  />
                  <label for="RemittanceAmount">匯款金額</label>
                </div>
                <!-- 備註 -->
                <div class="form-floating mb-3">
                  <textarea
                    class="form-control"
                    placeholder="備註"
                    id="Note"
                    style="height: 100px"
                    maxlength="300"
                    v-model="paymentNotification.note"
                  ></textarea>
                  <label for="Note">備註(300字)</label>
                </div>
                <!-- 匯款時間 -->
                <div class="form-floating mb-3">
                  <input
                    class="form-control"
                    type="datetime-local"
                    id="paymentTime"
                    name="meeting-time"
                    v-model="paymentNotification.paymentTime"
                    placeholder="付款時間"
                  />
                  <label for="paymentTime" class="mb-2">付款時間</label>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updatePaymentNotification"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除/恢復商品的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setDeletedModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bold
            "
          >
            刪除商品
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>確定要刪除這筆購買紀錄嗎 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setDeleted"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 檢視小幫手資訊的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="checkPermissionModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">小幫手資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="list" v-if="permissions.length > 0">
            <li v-for="(data, index) in permissions" :key="data.id">
              <span class="fw-bolder">
                <span class="text-success" v-if="data.participantPermissionId"
                  >欄位ID: {{ data.participantPermissionId }}</span
                >
                <span class="text-danger" v-else>欄位: 尚未啟用</span>
                <span>，區間: {{ data.period }}個月</span>
                <span v-if="data.enableTime">
                  ， 起始:
                  {{
                    $methods
                      .moment(data.enableTime)
                      .format("YYYY-MM-DD HH:mm:ss")
                  }}， 結束:
                  {{
                    $methods
                      .moment(data.expirationTime)
                      .format("YYYY-MM-DD HH:mm:ss")
                  }}
                </span>
              </span>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// api
import { parseToken, sortoutParseTokenResult } from '../../../methods/API/parseToken'

export default {
  data() {
    return {
      // modal
      paymentNotificationModal: {},
      setDeletedModal: {},
      checkPermissionModal: {},
      // data
      serverToken: '',
      subscribeResult: '',
      paymentRecords: [],
      paymentNotificationStatus: '',
      // 付款通知資料
      searchBankCode: '',
      banksCode: {
        loading: true,
        data: []
      },
      recordBanksCode: [],
      paymentNotification: {
        id: 0,
        money: 0,
        userPaymentInfoType: 'USERWIRETRANSFERINFO',
        bankCode: '000',
        accountNumber: '',
        note: '',
        paymentTime: ''
      },
      selectItems: [],
      canNotGoPayPlan: false,
      // 小幫手資訊
      permissions: []
    }
  },
  created() {
    this.initialization()
    this.getUserInfo()
    this.getBankCode()
  },
  mounted() {
    this.createModals(['paymentNotificationModal', 'setDeletedModal', 'checkPermissionModal'])
  },
  watch: {
    searchBankCode(val) {
      this.banksCode.data = this.recordBanksCode.filter(item => {
        const code = item.bankCode
        const name = item.name
        return code.indexOf(val) !== -1 || name.indexOf(val) !== -1
      })
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    showModal(status, data) {
      this.searchBankCode = ''
      if (status === 'paymentNotificationCreate') {
        // 建立已付款通知
        this.paymentNotificationStatus = 'create'
        this.paymentNotification = {
          id: data.paymentNotification.id,
          money: data.money,
          userPaymentInfoType: 'USERWIRETRANSFERINFO',
          bankCode: '000',
          accountNumber: '',
          note: '',
          paymentTime: ''
        }
        this.paymentNotificationModal.show()
      } else if (status === 'paymentNotificationUpdate') {
        // 更新已付款通知
        this.paymentNotificationStatus = 'update'
        this.paymentNotification = {
          id: data.paymentNotification.id,
          money: data.money,
          userPaymentInfoType: 'USERWIRETRANSFERINFO',
          bankCode: data.paymentNotification.userPaymentInfo.bankCode,
          accountNumber: data.paymentNotification.userPaymentInfo.accountNumber,
          note: data.paymentNotification.note,
          paymentTime: this.$methods.moment(data.paymentNotification.paymentTime).format("YYYY-MM-DDTHH:mm")
        }
        this.paymentNotificationModal.show()
      } else if (status === 'setDeleted') {
        // 刪除紀錄
        this.selectItems = []
        this.selectItems.push(data)
        this.setDeletedModal.show()
      } else if (status === 'checkPermission') {
        // 檢視小幫手資訊
        this.permissions = []
        this.permissions = data
        this.checkPermissionModal.show()
      }
    },
    // 取得使用者 ID
    getUserInfo() {
      this.$methods.switchLoading('show')
      const result = parseToken(this.serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.getPhysicalUserInfo(result)
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得實體使用者資訊
    getPhysicalUserInfo(userInfo) {
      const vm = this
      const getPhysicalUserInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 1,
          ids: userInfo.ids,
          methods: '{getStores,getSubscriptionProjects,getPaymentRecords{getPaymentRecordPermissionLinks,getPaymentNotification{getUserPaymentInfo},getPaymentRecordProjectLinks{getSubscriptionProject},isPaid,getPaymentVerifyRecords}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const userInfo = res.data[0].objects[0]
            vm.sortOutSubscribe(userInfo)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理訂閱資訊
    sortOutSubscribe(userInfo) {
      this.subscribeResult = ''
      if (userInfo.subscriptionProjects.length === 0 || userInfo.subscriptionProjects[0].expirationTime < new Date() * 1) this.subscribeResult = '目前尚未訂閱 !'
      else this.subscribeResult = `訂閱結束日期: ${this.$methods.moment(userInfo.subscriptionProjects[0].expirationTime).format('YYYY-MM-DD HH:mm:ss')}`
      userInfo.paymentRecords.sort((a, b) => {
        return b.id - a.id
      })
      if (userInfo.paymentRecords.length === 0) this.canNotGoPayPlan = true
      this.paymentRecords = userInfo.paymentRecords.filter(item => {return !item.deleted})
    },
    // 取得銀行代碼
    getBankCode () {
      const vm = this
      const getBankCodeApi = `${process.env.VUE_APP_API}/search/getBankCodeData`
      this.banksCode = {
        loading: true,
        data: []
      }
      this.recordBanksCode = []
      $.ajax({
        type: 'GET',
        async: true,
        url: getBankCodeApi,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            console.log(res.data)
            vm.banksCode = {
              loading: false,
              data: JSON.parse(JSON.stringify(res.data))
            }
            vm.recordBanksCode = JSON.parse(JSON.stringify(res.data))
          } else {
            vm.SweetAlert(res.code, res.message)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      })
    },
    // 付款通知
    updatePaymentNotification() {
      const vm = this
      const updatePaymentNotificationApi = `${process.env.VUE_APP_API}/paymentNotification/update`
      const header = {
        authorization: this.serverToken
      }
      if (!this.paymentNotification.paymentTime) return this.SweetAlert('other', '付款時間尚未填寫!')
      const data = [
        {
          id: this.paymentNotification.id,
          userPaymentInfoType: this.paymentNotification.userPaymentInfoType,
          userPaymentInfo: {
            bankCode: this.paymentNotification.bankCode,
            accountNumber: this.paymentNotification.accountNumber
          },
          money: parseInt(this.paymentNotification.money),
          note: this.paymentNotification.note,
          paymentTime: new Date(this.paymentNotification.paymentTime) * 1
        }
      ]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updatePaymentNotificationApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.paymentNotificationModal.hide()
            vm.getUserInfo()
          }
        },
        error: function(err) {
          console.log(err)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      })
    },
    // 刪除
    setDeleted() {
      const vm = this
      const setDeletedApi = `${process.env.VUE_APP_API}/paymentRecord/setDeleted`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('ids', this.selectItems[0].id)
      data.append('deleted', true)
      $.ajax({
        type: 'PUT',
        async: true,
        url: setDeletedApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.setDeletedModal.hide()
            vm.getUserInfo()
          }
        },
        error: function(err) {
          console.log(err)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      })
    },
  },
}
</script>